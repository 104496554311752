import React, { useContext} from "react";
import { Link } from "react-router-dom";
// import gift2Logo from "../media/gift-new2.png";
import { AuthState } from "../services/Context";

function Home() {
  const { userLoginState } = useContext(AuthState);
  const dataToken = JSON.parse(localStorage.getItem("data_token"));
  
  

  
  return (
    <div className="home-wrapper">
      <div className="wrapper-title">
        <h1>Plan for 2023</h1>
      </div>
      {/* <img className="img-medium" alt="gift" src={gift2Logo} /> */}
      
      <div>
        {userLoginState ? (
          <>
            <div>
              <b>{dataToken.name}</b>, welcome back.
              {/* <Link to="/profile">profile</Link> */}
            </div>
          </>
        ) : (
          <div>
            <>Only for authorized users.
            Please </>  
            <Link to="/login">login</Link>.
          </div>
        )}
      </div>{" "}
    </div>
  );
}

export default Home;
