import { TASKS_URL } from "./utils";


// const TASKS_URL = "http://localhost:5000/v1/tasks/";
// const TASKS_URL = "http://localhost:48889/v1/tasks/";
// const USERS_URL = "http://localhost:3000/v1/users/";

// const TASKS_URL = "https://tasks2023-8rev.onrender.com/v1/tasks/";

const getTasks = (token) => {
  return fetch(TASKS_URL + "tasks", {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

const  taskAdd = (token, data) => {
  return fetch(TASKS_URL + "addtask", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

const taskDelete = (token, data) => {
  return fetch(TASKS_URL + "delete", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

const UserService = {
  getTasks,
  taskAdd,
  taskDelete,
};

export default UserService;
