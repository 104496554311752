import React from "react";

function Header() {
  return (
    <div className="header-wrapper">
      <h1>2023 PLAN</h1>
    </div>
  );
}

export default Header;
