import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthState } from "../services/Context";

function Navbar() {
  const { userLoginState, setUserLoginState } = useContext(AuthState);

  const handleLogout = () => {
    localStorage.clear();
    setUserLoginState(false);
  };

  return (
    <>
      {userLoginState ? (
        <div className="navigation">
          <div className="nav-links">
            <Link className="nav-home" to="/">
              Home
            </Link>
          </div>
          <div className="nav-links">
            <Link className="nav-tasks" to="/tasks">
              Tasks
            </Link>
          </div>
          <div className="nav-links">
            <Link className="nav-stats" to="/stats">
              Statistics
            </Link>
          </div>
          <div className="nav-links">
            <Link className="nav-add" to="/add">
              Add data
            </Link>
          </div>
          <div className="nav-links">
            <Link className="nav-logout" to="/">
              <button className="logout-btn" onClick={handleLogout}>
                Logout
              </button>
            </Link>
          </div>
        </div>
      ) : (
        <div className="navigation">
          <div className="nav-links">
            <Link className="nav-home" to="/">
              Home
            </Link>
          </div>
          {/* <div className="nav-links">
            <Link className="nav-register" to="/register">
              Registration
            </Link>
          </div> */}
          <div className="nav-links">
            <Link className="nav-login" to="/login">
              Login
            </Link>
          </div>
        </div>
      )}
    </>
  );
}

export default Navbar;
