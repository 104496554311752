// const AUTH_URL = "https://tasks2023-8rev.onrender.com/v1/auth/";
// const TASKS_URL = "https://tasks2023-8rev.onrender.com/v1/tasks/";
const AUTH_URL_LOCAL = "http://localhost:48878/nodeapptasks/v1/auth/";
const TASKS_URL_LOCAL = "http://localhost:48878/nodeapptasks/v1/tasks/";
const AUTH_URL_REMOTE = "https://tasks.gpl.lt/nodeapptasks/v1/auth/";
const TASKS_URL_REMOTE = "https://tasks.gpl.lt/nodeapptasks/v1/tasks/";

const siteLocalization = "global";

if (siteLocalization === "local") {
  var AUTH_URL = AUTH_URL_LOCAL;

  var TASKS_URL = TASKS_URL_LOCAL;
} else {
  AUTH_URL = AUTH_URL_REMOTE;
  TASKS_URL = TASKS_URL_REMOTE;
}
export { AUTH_URL, TASKS_URL };

export function openUrl(url) {
  window.open(url, "_blank", "noopener,noreferrer");
}
