import { AUTH_URL } from "./utils";


// const AUTH_URL = "http://localhost:5000/v1/auth/";
// const AUTH_URL = "http://localhost:48889/v1/auth/";
// const AUTH_URL = "https://tasks2023-8rev.onrender.com/v1/auth/";



const setToken = (token, dataToken) => {
  localStorage.setItem("login_token", token);
  localStorage.setItem("data_token", JSON.stringify(dataToken));
};

const signUp = (data) => {
  return fetch(AUTH_URL + "register", {
    method: "POST",
    headers: {
      'Access-Control-Allow-Origin': '*',
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

const signIn = (data) => {
  return fetch(AUTH_URL + "login", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

const profile = (token) => {
  return fetch(AUTH_URL + "profile", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const confirmUser = (code) => {
  return fetch(AUTH_URL + "confirm/" + code);
};

const AuthService = {
  setToken,
  signUp,
  signIn,
  profile,
  confirmUser
};

export default AuthService;
