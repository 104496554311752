import React, { useContext, useState } from "react";
// import { useNavigate } from "react-router-dom";
import UserService from "../services/user.service";
import { AuthState } from "../services/Context";
// import { useEffect } from "react";

function AddTask() {
  const token = localStorage.getItem("login_token");
  const [taskDate, setTaskDate] = useState('');
  const [taskSleep, setTaskSleep] = useState('');
  const [taskMorningWk, setTaskMorningWk] = useState('');
  const [taskEveningWk, setTaskEveningWk] = useState('');
  const [taskCal, setTaskCal] = useState('');
  // const [taskWp, setTaskWp] = useState(false);
  const [isCheckedTaskWp, SetIsCheckedTaskWp] = useState(false);
  // const [taskAlk, setTaskAlk] = useState(false);
  const [isCheckedTaskAlk, SetIsCheckedTaskAlk] = useState(false);
  // const [taskFood, setTaskFood] = useState(false);
  const [isCheckedTaskFood, SetIsCheckedTaskFood] = useState(false);
  // const [formSubmit, setFormSubmit] = useState(false);
  // const navigate = useNavigate();
  const { userLoginState, setSubmitState } = useContext(AuthState);

  const handleChangeDate = (e) => {
    setTaskDate(e.target.value)
  }
  const handleChangeSleep = (e) => {
    setTaskSleep(e.target.value)
  }
  const handleChangeMWk = (e) => {
    setTaskMorningWk(e.target.value)
  }
  const handleChangeEWk = (e) => {
    setTaskEveningWk(e.target.value)
  }
  const handleChangeCal = (e) => {
    setTaskCal(e.target.value)
  }


  // console.log('alk',isCheckedTaskAlk)
  // console.log('wp',isCheckedTaskWp)
  // console.log('food', isCheckedTaskFood)
  // console.log('date', taskDate)
    
// useEffect(()=>{
//   navigate("/tasks", { replace: true });
// },[formSubmit, navigate])

  const handleSubmit = (event) => {
    event.preventDefault();
    const newTask = {
      date_task: taskDate,
      sleep_hrs: Number(taskSleep),
      morning_wk: Number(taskMorningWk),
      evening_wk: Number(taskEveningWk),
      calories: Number(taskCal),
      wp_task: isCheckedTaskWp,
      alk_task: isCheckedTaskAlk,
      food_cheat: isCheckedTaskFood,
      archived: 0,
    };

    if (
      !taskSleep ||
      !taskMorningWk ||
      !taskEveningWk ||
      !taskCal 
    ) {
      alert("Fill in all fields");
    } else {
      UserService.taskAdd(token, newTask).then(async function (response) {
        await response.json();
        setSubmitState(true);
        document.getElementById("add-task-form").reset();
        return
      });
    }
    
    // navigate("/tasks", { replace: true });
    // setFormSubmit(true);
  };

  return (
    <>
      {userLoginState ? (
        <div className="add-task">
          <div className="wrapper-title">
            <h1>Add new record:</h1>
          </div>

          <div className="add-task-form-wrapper">
            <form id="add-task-form">
            <label htmlFor="task-date">
                Date:<span className="red">*</span>
              </label>
              <input
                value={taskDate}
                type="date"
                id="inp-date"
                name="inp-date"
                onChange={handleChangeDate}
              />
              <label htmlFor="task-sleep">
                Sleep minutes:<span className="red">*</span>
              </label>
              <input
                value={taskSleep}
                type="number"
                id="inp-sleep"
                name="inp-sleep"
                onChange={handleChangeSleep}
              />

              <label htmlFor="task-m-wk">
                Morning workout:<span className="red">*</span>
              </label>
              <input
                value={taskMorningWk}
                type="number"
                id="inp-m-wk"
                name="inp-m-wk"
                onChange={handleChangeMWk}
              />

              <label htmlFor="task-e-wk">
                Evening workout:<span className="red">*</span>
              </label>
              <input
                value={taskEveningWk}
                type="number"
                id="inp-e-wk"
                name="inp-e-wk"
                onChange={handleChangeEWk}
              />

              <label htmlFor="task-cal">
                Calories:<span className="red">*</span>
              </label>
              <input
                value={taskCal}
                type="number"
                id="inp-cal"
                name="inp-cal"
                onChange={handleChangeCal}
              />

              <label htmlFor="task-wp">
                WP:<span className="red">*</span>
              </label>
              <input
                checked={isCheckedTaskWp}
                type="checkbox"
                id="inp-wp"
                name="inp-wp"
                onChange={() => SetIsCheckedTaskWp((prev) => !prev)}
              />

              <label htmlFor="task-alk">
                ALK:<span className="red">*</span>
              </label>
              <input
                checked={isCheckedTaskAlk}
                type="checkbox"
                id="inp-alk"
                name="inp-alk"
                onChange={() => SetIsCheckedTaskAlk((prev) => !prev)}
              />

              <label htmlFor="task-food">
                Food cheat:<span className="red">*</span>
              </label>
              <input
                checked={isCheckedTaskFood}
                type="checkbox"
                id="inp-food"
                name="inp-food"
                onChange={() => SetIsCheckedTaskFood((prev) => !prev)}
              />

              <div className="required">* required</div>
              <div>
                <button
                  className="form-submit-btn"
                  onClick={handleSubmit}
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className="error">
          <h1>You are not authorized to access this page</h1>
        </div>
      )}
    </>
  );
}

export default AddTask;
