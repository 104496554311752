import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthState } from "../services/Context";
import UserService from "../services/user.service";
import AddTask from "./AddTask";
import Loading from "./Loading";

function Tasks() {
  const { userLoginState, submitState } = useContext(AuthState);
  const [tasksData, setTasksData] = useState([]);
  const [delState, setDelState] = useState(false);
  const [loading, setLoading] = useState();
  const [showForm, setShowForm] = useState(false);
  
  const token = localStorage.getItem("login_token");

  useEffect(() => {
    if (userLoginState) {
      setLoading(true);
      UserService.getTasks(token)
        .then(function (response) {
          setLoading(false);
          return response.json();
        })
        .then((data) => {
          let sortedByDate = data.results[0].sort(
            (p1, p2) => (p1.date_task < p2.date_task) ? 1 : (p1.date_task > p2.date_task) ? -1 : 0);
          setTasksData(sortedByDate);
          // console.log(data.results[0])
        });
        
    }
  }, [token, userLoginState, delState, submitState]);

  // console.log(tasksData);

  const showFormClick = () => {
    setShowForm(!showForm);
  }

  const deleteTask = (taskId, taskName) => {
    const trimTaskName = new Date(taskName).toLocaleDateString('lt-LT');
    if (
      window.confirm(`Are you sure you want to delete: ${trimTaskName}`) ===
      true
    ) {
      const delTask = {
        id: taskId,
      };
      UserService.taskDelete(token, delTask).then(function (response) {
        return response.json();
      });
      // console.log(taskId);
      // console.log(taskName);
      setDelState(true);
    }
  };

  const checkTaskExists = loading ? (
    <><div>Loading...</div>
    <Loading/></>
  ) : tasksData.length === 0 ? (
    <>
      <div>
        You don't have any records yet. You can add one{" "}
        <Link to="/addtask">here.</Link>
      </div>
    </>
  ) : (
    <h1>Your records</h1>
  );

  return (
    <>
      {userLoginState ? (
        <>
        <button onClick={showFormClick}>{showForm ? "Hide" : "Add record"}</button>
        {showForm && (
        
          <AddTask/>
        
      )}
        
        <div className="tasks-wrapper">
          <div className="tasks-title">{checkTaskExists}</div>
          <div className="tasks-container">
            {tasksData.map((task, index) => (
              <div className="single-task-container" key={task.id}>
                {/* <div className="tasks-image-wrap">
                  <img
                    className="gift-logo"
                    src={giftLogo}
                    alt="gift logo"
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                </div> */}
                <div className="task-info-wrap">
                  <div className="task-single-data-title">
                    <div className="single-task-date-value">
                      {new Date(task.date_task).toLocaleDateString('lt-LT')}
                    </div>
                  </div>
                  <div className="task-single-data">
                    <div className="single-task-sleep">Sleep:</div>
                    <div
                      className={
                        task.sleep_hrs >= 480
                          ? "single-task-green-value"
                          : "single-task-red-value"
                      }
                    >
                      {Math.round(((task.sleep_hrs/60) + Number.EPSILON)*100)/100}
                    </div>
                  </div>
                  <div className="task-single-data">
                    <div className="single-task-morningwk">
                      Morning workout:
                    </div>
                    <div className="single-task-morningwk-value">
                      {task.morning_wk}
                    </div>
                  </div>
                  <div className="task-single-data">
                    <div className="single-task-eveningwk">
                      Evening workout:
                    </div>
                    <div className="single-task-eveningwk-value">
                      {task.evening_wk}
                    </div>
                  </div>
                  <div className="task-single-data">
                    <div className="single-task-calories">Calories burned:</div>
                    <div className="single-task-calories-value">
                      {task.calories}
                    </div>
                  </div>
                  <div className="task-single-data">
                    <div className="single-task-wp">WP:</div>
                    <div
                      className={
                        task.wp_task === 0
                          ? "single-task-green-value"
                          : "single-task-red-value"
                      }
                    >
                      {task.wp_task === 0 ? "No" : "Yes"}
                    </div>
                  </div>
                  <div className="task-single-data">
                    <div className="single-task-alk">ALK:</div>
                    <div
                      className={
                        task.alk_task === 0
                          ? "single-task-green-value"
                          : "single-task-red-value"
                      }
                    >
                      {task.alk_task === 0 ? "No" : "Yes"}
                    </div>
                  </div>
                  <div className="task-single-data">
                    <div className="single-task-food">Food:</div>
                    <div
                      className={
                        task.food_cheat === 0
                          ? "single-task-green-value"
                          : "single-task-red-value"
                      }
                    >
                      {task.food_cheat === 0 ? "No" : "Yes"}
                    </div>
                  </div>
                  <div className="single-task-delete">
                    <button
                      id={index}
                      onClick={() =>
                        deleteTask(task._id.toString(), task.date_task)
                      }
                    >
                      Delete record
                    </button>
                  </div>
                  <div className="single-task-update">
                    <button
                      id={index}
                      onClick={() =>
                        deleteTask(task._id.toString(), task.date_task)
                      }
                    >
                      Edit record
                    </button>
                  </div>
                </div>

                <div></div>
              </div>
            ))}
          </div>
        </div>
        </>
      ) : (
        <div className="error">
          <h1>You are not authorized to access this page</h1>
        </div>
      )}
      
    </>
    
  );
}

export default Tasks;
